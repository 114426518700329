import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Admin from './pages/Admin';
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Verifica se o token existe para definir a autenticação
  }, []);

  return (
    <ChakraProvider>
      <Router>
        {/* O Header sempre estará visível */}
        <Header />
        
        <Box as="main" p="4" pt="100px">
          <Routes>
            {/* Redireciona para a página de login como rota inicial */}
            <Route path="/" element={<Navigate to="/login" />} />

            {/* Página de login */}
            <Route path="/login" element={<Login />} />

            {/* Página de recuperação de senha */}
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Rota protegida para o painel de administrador */}
            <Route
              path="/admin"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Admin />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>

        {/* O Footer sempre estará visível */}
        <Footer />
      </Router>
    </ChakraProvider>
  );
};

export default App;


