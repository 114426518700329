import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <Box
      overflow="hidden"
      as="header"
      bg="#F2F0D5"
      py={4}
      px={8}
      borderBottom="1px solid #D9BB25"
      position="fixed"
      top="0"
      width="100%"
      zIndex="1000"
      fontFamily="'Montserrat', sans-serif"
    >
      <Flex direction="column" align="center">
        <RouterLink to="/">
          <Image 
            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} 
            alt="Portal Recanto das Camomilas" 
            borderRadius="1rem" 
            maxWidth="89px"
            mb={2}
            cursor="pointer"
          />
        </RouterLink>
      </Flex>
    </Box>
  );
};

export default Header;
