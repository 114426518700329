import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box, Input, Button, FormControl, FormLabel, Heading, InputGroup, InputRightElement,
  IconButton, useToast
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'; // Ícones para mostrar/esconder senha

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false); // Estado para controlar a visibilidade da senha
  const navigate = useNavigate();
  const toast = useToast();

  // Função para obter o CSRF token
  const getCsrfToken = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`, {
        withCredentials: true
      });
    } catch (error) {
      console.error("Erro ao obter o token CSRF:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Primeiro, obtenha o token CSRF
      await getCsrfToken();

      // Agora faça a requisição de login com o token CSRF
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password
      }, {
        withCredentials: true, // Isso permite enviar cookies com a requisição
      });

      // Armazena o token no localStorage (se necessário, dependendo da estratégia de autenticação)
      localStorage.setItem('token', response.data.token);

      toast({
        title: 'Login bem-sucedido',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Redireciona para o painel de administrador
      navigate('/admin');
    } catch (error: any) {
      console.error('Erro ao fazer login:', error.response ? error.response.data : error.message);
      toast({
        title: 'Erro de login',
        description: error.response?.data?.message || 'Email ou senha incorretos',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="sm" mx="auto" mt="10" p="6" borderWidth="1px" borderRadius="lg" boxShadow="lg">
      <Heading mb="6" textAlign="center">Login</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" mb="4">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
            required
          />
        </FormControl>

        <FormControl id="password" mb="4">
          <FormLabel>Senha</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'} // Alterna entre 'text' e 'password'
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              required
            />
            <InputRightElement>
              <IconButton
                aria-label={showPassword ? 'Esconder senha' : 'Mostrar senha'}
                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />} // Altera ícone baseado no estado
                onClick={() => setShowPassword(!showPassword)} // Alterna visibilidade
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Button type="submit" colorScheme="teal" width="full" mt="4">
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;


