import React, { useState } from 'react';
import axios from 'axios';
import { Box, Input, Button, FormControl, FormLabel, Heading, useToast } from '@chakra-ui/react';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useToast();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Faz a requisição para a rota de recuperação de senha
      await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email });

      // Exibe uma mensagem de sucesso
      toast({
        title: "Email enviado.",
        description: "Verifique seu email para redefinir a senha.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // Exibe uma mensagem de erro
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao tentar enviar o email de recuperação.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box maxW="sm" mx="auto" mt="10" p="6" borderWidth="1px" borderRadius="lg" boxShadow="lg">
      <Heading mb="6" textAlign="center">Recuperar Senha</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" mb="4">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full" mt="4" isLoading={isSubmitting}>
          Enviar Email de Recuperação
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
