import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, Textarea, Stack, FormLabel, Heading, List, ListItem, IconButton } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';

interface Rule {
  id: number;
  titulo: string;
  descricao: string;
}

interface NewsItem {
  id: number;
  titulo: string;
  resumo: string;
  data_publicacao: string;
  imagem?: string;
}

const Admin: React.FC = () => {
  const [titulo, setTitle] = useState<string>('');
  const [resumo, setContent] = useState<string>('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [rules, setRules] = useState<Rule[]>([]);
  const [ruleTitle, setRuleTitle] = useState<string>('');
  const [ruleDescription, setRuleDescription] = useState<string>('');
  const [news, setNews] = useState<NewsItem[]>([]);
  const [editingNewsId, setEditingNewsId] = useState<number | null>(null);
  const [editingRuleId, setEditingRuleId] = useState<number | null>(null);

  const navigate = useNavigate();
  const isAuthenticated = true; // Substitua por sua lógica de autenticação real

  useEffect(() => {
    console.log("Verificando autenticação:", isAuthenticated);
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    axios.get<Rule[]>(`${process.env.REACT_APP_API_URL}/rules`)
      .then(response => {
        console.log('Regras recebidas:', response.data);
        setRules(response.data);
      })
      .catch(error => console.error('Erro ao buscar regras:', error));

    axios.get<NewsItem[]>(`${process.env.REACT_APP_API_URL}/news`)
      .then(response => {
        console.log('Notícias recebidas:', response.data);
        setNews(response.data);
      })
      .catch(error => console.error('Erro ao buscar notícias:', error));
  }, []);

  const handleAddOrUpdateNews = async () => {
    if (!titulo || !resumo) {
      alert('Título e Resumo são obrigatórios.');
      return;
    }

    try {
      const newsData = [
        { key: 'title', value: titulo },
        { key: 'resumo', value: resumo },
        ...(imageFile ? [{ key: 'image', value: imageFile }] : []),
      ];

      const formData = new FormData();
      newsData.forEach(item => formData.append(item.key, item.value));

      console.log("Dados da notícia a serem enviados:", newsData);

      if (editingNewsId) {
        console.log("Editando notícia com ID:", editingNewsId);
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/news/${editingNewsId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-HTTP-Method-Override': 'PUT'
          },
        });
        console.log("Response ao editar notícia:", response.data);
        setNews(news.map(item =>
          item.id === editingNewsId
            ? { ...item, titulo, resumo, imagem: imageFile ? URL.createObjectURL(imageFile) : item.imagem }
            : item
        ));
        alert('Notícia editada com sucesso!');
        setEditingNewsId(null);
      } else {
        console.log("Adicionando nova notícia");
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/news`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log("Response ao adicionar notícia:", response.data);
        setNews([...news, {
          id: response.data.id, titulo, resumo, data_publicacao: new Date().toISOString(), imagem: imageFile ? URL.createObjectURL(imageFile) : undefined,
        }]);
        alert('Notícia adicionada com sucesso!');
      }

      setTitle('');
      setContent('');
      setImageFile(null);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Erro ao adicionar ou atualizar notícia:', axiosError.response.data);
      } else {
        console.error('Erro ao adicionar ou atualizar notícia:', axiosError);
      }
      alert('Erro ao adicionar ou atualizar notícia.');
    }
  };

  const handleEditNews = (newsItem: NewsItem) => {
    console.log("Editando notícia:", newsItem);
    setTitle(newsItem.titulo);
    setContent(newsItem.resumo);
    setEditingNewsId(newsItem.id);
  };

  const handleDeleteNews = async (id: number) => {
    if (!id) {
      alert('ID da notícia é indefinido.');
      return;
    }

    try {
      console.log("Excluindo notícia com ID:", id);
      await axios.delete(`${process.env.REACT_APP_API_URL}/news/${id}`);
      setNews(news.filter(item => item.id !== id));
      alert('Notícia excluída com sucesso.');
    } catch (error) {
      console.error('Erro ao excluir notícia:', error);
      alert('Erro ao excluir notícia.');
    }
  };

  const handleAddOrUpdateRule = async () => {
    if (!ruleTitle || !ruleDescription) {
      alert('Título e Descrição da Regra são obrigatórios.');
      return;
    }

    try {
      if (editingRuleId) {
        console.log("Editando regra com ID:", editingRuleId);
        await axios.put(`${process.env.REACT_APP_API_URL}/rules/${editingRuleId}`, {
          titulo: ruleTitle,
          descricao: ruleDescription,
        });
        setRules(rules.map(rule => rule.id === editingRuleId ? { ...rule, titulo: ruleTitle, descricao: ruleDescription } : rule));
        setEditingRuleId(null);
        alert('Regra editada com sucesso!');
      } else {
        console.log("Adicionando nova regra");
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/rules`, {
          titulo: ruleTitle,
          descricao: ruleDescription,
        });
        console.log("Response ao adicionar regra:", response.data);
        setRules([...rules, { id: response.data.id, titulo: ruleTitle, descricao: ruleDescription }]);
        alert('Regra adicionada com sucesso!');
      }

      setRuleTitle('');
      setRuleDescription('');
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.error('Erro ao adicionar ou atualizar regra:', axiosError.response.data);
      } else {
        console.error('Erro ao adicionar ou atualizar regra:', axiosError);
      }
      alert('Erro ao adicionar ou atualizar regra.');
    }
  };

  const handleEditRule = (rule: Rule) => {
    console.log("Editando regra:", rule);
    setRuleTitle(rule.titulo);
    setRuleDescription(rule.descricao);
    setEditingRuleId(rule.id);
  };

  const handleDeleteRule = async (id: number) => {
    try {
      console.log("Excluindo regra com ID:", id);
      await axios.delete(`${process.env.REACT_APP_API_URL}/rules/${id}`);
      setRules(rules.filter(rule => rule.id !== id));
      alert('Regra excluída com sucesso.');
    } catch (error) {
      console.error('Erro ao excluir regra:', error);
      alert('Erro ao excluir regra.');
    }
  };

  return (
    <Box p={5} maxWidth="800px" mx="auto" paddingTop="80px">
      <Heading mb={5}>Gestão de Notícias</Heading>
      <Stack spacing={3} mb={10}>
        <FormLabel htmlFor="title">Título da Notícia</FormLabel>
        <Input
          id="title"
          placeholder="Título da Notícia"
          value={titulo}
          onChange={(e) => setTitle(e.target.value)}
        />
        <FormLabel htmlFor="content">Resumo da Notícia</FormLabel>
        <Textarea
          id="content"
          placeholder="Resumo da Notícia"
          value={resumo}
          onChange={(e) => setContent(e.target.value)}
        />
        <FormLabel htmlFor="image">Imagem</FormLabel>
        <Input
          id="image"
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files?.[0] || null)}
        />
        <Button onClick={handleAddOrUpdateNews} colorScheme="teal">
          {editingNewsId ? "Atualizar Notícia" : "Adicionar Notícia"}
        </Button>
      </Stack>
      <Heading mb={5}>Gestão de Regras</Heading>
      <Stack spacing={3}>
        <FormLabel htmlFor="ruleTitle">Título da Regra</FormLabel>
        <Input
          id="ruleTitle"
          placeholder="Título da Regra"
          value={ruleTitle}
          onChange={(e) => setRuleTitle(e.target.value)}
        />
        <FormLabel htmlFor="ruleDescription">Descrição da Regra</FormLabel>
        <Textarea
          id="ruleDescription"
          placeholder="Descrição da Regra"
          value={ruleDescription}
          onChange={(e) => setRuleDescription(e.target.value)}
        />
        <Button onClick={handleAddOrUpdateRule} colorScheme="teal">
          {editingRuleId ? "Atualizar Regra" : "Adicionar Regra"}
        </Button>
      </Stack>

      <Box mt={10}>
        <Heading size="lg" mb={4}>Regras Atuais</Heading>
        <List spacing={3}>
          {rules.map((rule) => (
            <ListItem key={rule.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Text fontWeight="bold">{rule.titulo}</Text>
                <Text>{rule.descricao}</Text>
              </Box>
              <Box>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditRule(rule)}
                  colorScheme="blue"
                  size="sm"
                  mr={2}
                  aria-label="Editar"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteRule(rule.id)}
                  colorScheme="red"
                  size="sm"
                  aria-label="Excluir"
                />
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box mt={10}>
        <Heading size="lg" mb={4}>Notícias Atuais</Heading>
        <List spacing={3}>
          {news.map((item) => (
            <ListItem key={item.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Text>{item.titulo}</Text>
                <Text>{item.resumo}</Text>
              </Box>
              <Box>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditNews(item)}
                  colorScheme="blue"
                  size="sm"
                  mr={2}
                  aria-label="Editar"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteNews(item.id)}
                  colorScheme="red"
                  size="sm"
                  aria-label="Excluir"
                />
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default Admin;
